import BackgroundLayers from '@/components/layouts/BackgroundLayers/BackgroundLayers';
import Loader from '@/components/base/Loader';

const LoaderPage = () => {
    return (
        <>
            <div className="absolute inset-0 -z-1 overflow-hidden" data-testid="loader-page">
                <BackgroundLayers className="absolute inset-0 h-full min-h-full w-full" />
            </div>
            <Loader className="absolute left-1/2 top-1/2 mr-3 h-8 w-8 text-icoBlue" />
        </>
    );
};

export default LoaderPage;
