import { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { matchSorter } from 'match-sorter';

//hooks
import useUser from '@/utils/hooks/swr/useUser';
import useSocialProfiles from '@/hooks/swr/useSocialProfiles';
import useActiveSocialProfile from '@/utils/hooks/user/useActiveSocialProfile';
import {
    useGlobalInfo,
    UPDATE_SIGN_UP_STEP,
    TOGGLE_ACTIVE_MENU_GROUP,
    TOGGLE_MENU_SELECTED_GROUP_OPEN,
    UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
    INITIAL_ACTIVE_GROUP
} from '@/hooks/context/useGlobalInfo';
import useLanguage from '@/utils/hooks/language/useLanguage';
import useActiveGroup from '@/utils/hooks/user/useActiveGroup';
import useSPGroups, { SpGroupType } from '@/hooks/swr/group/useSPGroups';

//Routes
import { SIGN_UP, GROUPS } from '@/lib/routes/privateRoutes';
import * as publicRoutes from '@/lib/routes/publicRoutes';
import useUrlUserHomepage from '@/utils/hooks/user/useUrlUserHomepage';

//types
import type { SocialProfileType } from '@/types/socialProfileType';
import type { UserSignUpStep } from '@/types/userType';
import type { MatchSorterOptions } from 'match-sorter';

const key_local_storage = 'ico-social-profiles-active';

const signupSteps = ['USER_CREATED', 'USER_SET_UP', 'SOCIAL_PROFILES_CONNECTED'] as const;

const SORT_KEYS: MatchSorterOptions<SocialProfileType> = {
    keys: [{ threshold: matchSorter.rankings.EQUAL, key: 'id' }]
};
const useSession = ({ isLoggedRedirect = false } = {}) => {
    const [loadingSessionUser, setIsLoadingSessionUser] = useState(true);
    const [loadingSessionSocialProfiles, setIsLoadingSessionSocialProfiles] = useState(true);
    const { user, isLoading: isLoadingUser } = useUser({ revalidateOnFocus: true });
    const { useSetLanguage } = useLanguage();
    const { activatedSocialProfiles, isLoading: isLoadingSocialProfiles } = useSocialProfiles();
    const { isLoading: isLoadingGroups, spGroups } = useSPGroups();
    const router = useRouter();
    const { globalInfo, dispatch } = useGlobalInfo();
    const publicPages = Object.entries(publicRoutes).map((el) => el[1]);

    const { userHomepage } = useUrlUserHomepage();

    const formatUrlWithParams = useCallback(
        (url: string) => {
            if (router.query && Object.entries(router.query)?.length) {
                return `${url}?${queryString.stringify(router.query, { arrayFormat: 'bracket' })}`;
            }
            return url;
        },
        [router]
    );

    function redirectToHomepage() {
        router.push(formatUrlWithParams(userHomepage));
    }

    const { activeSocialProfile, setActiveSocialProfile } = useActiveSocialProfile();
    const { activeGroup } = useActiveGroup();
    useSetLanguage();

    //redirect to right active menu on routeChange
    useEffect(() => {
        if ((isLoadingGroups && isLoadingSocialProfiles) || isLoadingUser) {
            return;
        }

        function handleRouteChange(e: string) {
            if (activeGroup) {
                if (!globalInfo.menuGroupActive) {
                    dispatch({ type: TOGGLE_ACTIVE_MENU_GROUP, payload: true });
                }
                if (!globalInfo.menuSelectedGroupOpen) {
                    dispatch({ type: TOGGLE_MENU_SELECTED_GROUP_OPEN, payload: true });
                }
            }
            if (
                !activeGroup &&
                globalInfo.menuGroupActive &&
                (spGroups?.length || (!spGroups?.length && !e.includes(GROUPS)))
            ) {
                dispatch({ type: TOGGLE_ACTIVE_MENU_GROUP, payload: false });
            }
        }
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        router,
        isLoadingGroups,
        isLoadingUser,
        isLoadingSocialProfiles,
        activeGroup,
        globalInfo.menuGroupActive
    ]);

    //set information in context if there is an active group in local storage
    useEffect(() => {
        if (isLoadingGroups || isLoadingUser) {
            return;
        }

        const groupInLocalStorage = window.localStorage.getItem('ico-group-active');
        const groupActive: SpGroupType =
            groupInLocalStorage && groupInLocalStorage !== 'undefined'
                ? JSON.parse(groupInLocalStorage)
                : null;
        const activeSocialProfileInStorage = window.localStorage.getItem(
            'ico-group-profiles-active'
        );
        const activeSocialProfileGroup: SocialProfileType =
            activeSocialProfileInStorage && activeSocialProfileInStorage !== 'undefined'
                ? JSON.parse(activeSocialProfileInStorage)
                : null;

        if (groupActive) {
            dispatch({ type: TOGGLE_ACTIVE_MENU_GROUP, payload: true });
            dispatch({ type: TOGGLE_MENU_SELECTED_GROUP_OPEN, payload: true });
            if (
                activeSocialProfileGroup &&
                groupActive.social_profiles.find((el) => el.id === activeSocialProfileGroup.id)
            ) {
                dispatch({
                    type: UPDATE_ACTIVE_SOCIAL_PROFILE_IN_GROUP,
                    payload: activeSocialProfileGroup
                });
            }
        }
    }, [dispatch, isLoadingGroups, isLoadingUser]);

    //clear local storage for group if no group returned by endpoint
    useEffect(() => {
        if (isLoadingGroups || spGroups?.length || isLoadingUser) {
            return;
        }

        window.localStorage.removeItem('ico-group-active');
        window.localStorage.removeItem('ico-group-profiles-active');
    }, [isLoadingUser, isLoadingGroups, spGroups?.length]);

    // Update Active Social profile
    useEffect(() => {
        if (isLoadingSocialProfiles || isLoadingUser) {
            return;
        }
        const localStorage = window.localStorage.getItem(key_local_storage);
        const groupInLocalStorage = window.localStorage.getItem('ico-group-active');
        const activeSocialProfileInLocalStorage: SocialProfileType =
            localStorage && localStorage !== 'undefined' ? JSON.parse(localStorage) : null;
        const groupActive: SpGroupType =
            groupInLocalStorage && groupInLocalStorage !== 'undefined'
                ? JSON.parse(groupInLocalStorage)
                : null;

        if (groupActive && activatedSocialProfiles.length) {
            if (activeGroup?.id !== groupActive.id) {
                dispatch({ type: INITIAL_ACTIVE_GROUP, payload: groupActive });
            }
            return setIsLoadingSessionSocialProfiles(false);
        }

        if (!activatedSocialProfiles.length) {
            // Remove current local storage if array is empty
            if (!activatedSocialProfiles.length && localStorage) {
                window.localStorage.removeItem(key_local_storage);
            }
            return setIsLoadingSessionSocialProfiles(false);
        }

        const firstSocialProfile = activatedSocialProfiles[0];
        // Active social profile from local storage

        if (!activeSocialProfileInLocalStorage) {
            if (
                matchSorter(activatedSocialProfiles, activeSocialProfile?.id.toString(), SORT_KEYS)
                    .length > 0
            ) {
                window.localStorage.setItem(key_local_storage, JSON.stringify(activeSocialProfile));
                return setIsLoadingSessionSocialProfiles(false);
            }
            window.localStorage.setItem(key_local_storage, JSON.stringify(firstSocialProfile));
            setActiveSocialProfile(firstSocialProfile);
            return setIsLoadingSessionSocialProfiles(false);
        }
        if (activeSocialProfile) {
            if (
                matchSorter(activatedSocialProfiles, activeSocialProfile?.id.toString(), SORT_KEYS)
                    .length > 0
            ) {
                // Check if different from social profile in local storage
                if (activeSocialProfileInLocalStorage?.id !== activeSocialProfile?.id) {
                    // Set active SP in global info to local storage
                    return window.localStorage.setItem(
                        key_local_storage,
                        JSON.stringify(activeSocialProfile)
                    );
                }
                return setIsLoadingSessionSocialProfiles(false);
            }

            // Reset active social profile to first social profile in the list
            window.localStorage.setItem(key_local_storage, JSON.stringify(firstSocialProfile));
            setActiveSocialProfile(firstSocialProfile);
            return setIsLoadingSessionSocialProfiles(false);
        }
        // Check if SP in local storage still in social profiles list
        if (
            matchSorter(
                activatedSocialProfiles,
                activeSocialProfileInLocalStorage?.id.toString(),
                SORT_KEYS
            ).length > 0
        ) {
            // Set active SP in localstorage to global info
            return setActiveSocialProfile(activeSocialProfileInLocalStorage);
        }
        // Reset active social profile to first social profile in the list
        window.localStorage.setItem(key_local_storage, JSON.stringify(firstSocialProfile));
        setActiveSocialProfile(firstSocialProfile);
        return setIsLoadingSessionSocialProfiles(false);
    }, [
        isLoadingUser,
        activatedSocialProfiles,
        activeSocialProfile,
        setActiveSocialProfile,
        isLoadingSocialProfiles,
        activeGroup?.id,
        dispatch
    ]);

    useEffect(() => {
        if (isLoadingUser || isLoadingSocialProfiles) {
            return;
        }

        const signup_step = user?.sign_up_step?.toUpperCase() as Uppercase<UserSignUpStep>;
        const currentUrl = router?.asPath ?? '';
        //user hasn't finished the signup process
        if (signup_step !== 'SIGN_UP_DONE' && signupSteps.includes(signup_step)) {
            //if signup step not set in globalInfo context
            if (!globalInfo.signup.currentStep) {
                const user_id = user.id;
                const cookie_signup_step = Cookies.get(
                    `ico-signup-step-${user_id}`
                ) as Uppercase<UserSignUpStep>;
                const current_signup_step = !user.email_verified_at
                    ? 'USER_EMAIL_VERIFICATION'
                    : cookie_signup_step ?? signup_step;
                dispatch({
                    type: UPDATE_SIGN_UP_STEP,
                    payload: current_signup_step
                });
                if (currentUrl !== formatUrlWithParams(SIGN_UP[current_signup_step])) {
                    router.push(formatUrlWithParams(SIGN_UP[current_signup_step]));
                }
                return setIsLoadingSessionUser(false);
            }
            //if signup step in globalInfo context
            if (currentUrl !== formatUrlWithParams(SIGN_UP[globalInfo.signup.currentStep])) {
                if (globalInfo.signup.currentStep === 'SIGN_UP_DONE') {
                    redirectToHomepage();
                }
                router.push(formatUrlWithParams(SIGN_UP[globalInfo.signup.currentStep]));
                return;
            }
            return setIsLoadingSessionUser(false);
        }
        // user is on public pages and is authenticated
        if (isLoggedRedirect && user && formatUrlWithParams(userHomepage) !== currentUrl) {
            redirectToHomepage();
            return;
        }
        // user auth && signup done
        if (
            user &&
            Object.entries(SIGN_UP).filter((el) => formatUrlWithParams(el[1]) === currentUrl)
                .length !== 0
        ) {
            redirectToHomepage();
            return;
        }
        return setIsLoadingSessionUser(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formatUrlWithParams,
        globalInfo.signup.currentStep,
        isLoadingUser,
        isLoadingSocialProfiles,
        router,
        user,
        isLoggedRedirect,
        publicPages
    ]);

    return {
        loading: loadingSessionUser || loadingSessionSocialProfiles,
        session: user ? user : null
    };
};
export default useSession;
