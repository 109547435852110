import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

// Hooks
import useUser from '@/utils/hooks/swr/useUser';

// Types
import type { PlatformLanguagesType } from '@/types/userType';

// Utils
import {
    getLocalStorageLanguage,
    setLocalStorageLanguage
} from '@/utils/localStorage/languageLocalStorage';

const useLanguage = () => {
    // Makes sure that the right language is used
    const useSetLanguage = () => {
        const { lang } = useTranslation();
        const { user } = useUser();
        const platformLanguage = user?.settings?.platform_language;

        useEffect(() => {
            const localStorageLanguage = getLocalStorageLanguage();

            if (!localStorageLanguage) {
                setLocalStorageLanguage('en');
            }
            if (platformLanguage) {
                if (platformLanguage !== localStorageLanguage) {
                    setLocalStorageLanguage(platformLanguage);
                    setLanguage(platformLanguage);
                } else if (
                    platformLanguage === localStorageLanguage &&
                    lang !== localStorageLanguage
                ) {
                    setLanguage(platformLanguage);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [platformLanguage]);
    };

    const setLanguageLocalStorage = (platformLanguage: PlatformLanguagesType) => {
        const localStorageLanguage = getLocalStorageLanguage();

        if (!localStorageLanguage) {
            setLocalStorageLanguage(platformLanguage);
        }

        if (platformLanguage !== localStorageLanguage) {
            setLocalStorageLanguage(platformLanguage);
            setLanguage(platformLanguage);
        }
    };
    return { useSetLanguage, setLanguageLocalStorage };
};

export default useLanguage;
