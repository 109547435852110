import { PlatformLanguagesType } from '@/types/userType';

const localStorageLanguageKey = 'ico-language';
const defaultValue: PlatformLanguagesType = 'en';

export const getLocalStorageLanguage = () => {
    return (localStorage.getItem(localStorageLanguageKey) as PlatformLanguagesType) ?? defaultValue;
};

export const setLocalStorageLanguage = (language: PlatformLanguagesType) => {
    if (language === defaultValue) {
        localStorage.removeItem(localStorageLanguageKey);
        return;
    }
    localStorage.setItem(localStorageLanguageKey, language);
};
