import type { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';

type BackgroundLayerProps = ComponentPropsWithoutRef<'div'>;
const BackgroundLayers = ({ className, ...restProps }: BackgroundLayerProps) => {
    return (
        <div
            className={clsx(`bg-icoGray-300 dark:bg-icoDarkMode-goudron`, className)}
            {...restProps}>
            <div className="absolute inset-0 opacity-[0.08] dark:hidden">
                <div className="absolute inset-0 bg-icoBaseLayer" />
            </div>
        </div>
    );
};

export default BackgroundLayers;
