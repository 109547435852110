import useUser from '@/utils/hooks/swr/useUser';

// Libs
import {
    ANALYZE_OVERVIEW,
    CONTENT_POSTS,
    HOMEPAGE,
    LISTENING_COMPETITORS,
    MANAGE_COMMENTS_TRACKER,
    SCHEDULER
} from '@/lib/routes/privateRoutes';

const useUrlUserHomepage = () => {
    const { user } = useUser();

    const settingsHomepage = user?.settings?.homepage ?? 'welcome_page';

    const urlUserHomepage = {
        analyze_overview: ANALYZE_OVERVIEW,
        content_posts: CONTENT_POSTS,
        conversations: MANAGE_COMMENTS_TRACKER,
        publishing_scheduler: SCHEDULER,
        listening_competitors: LISTENING_COMPETITORS,
        welcome_page: HOMEPAGE
    } as const;

    return { userHomepage: urlUserHomepage[settingsHomepage] };
};

export default useUrlUserHomepage;
